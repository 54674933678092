import isEqual from "lodash-es/isEqual";
import isEmpty from "lodash-es/isEmpty";
import capitalize from "lodash-es/capitalize";
import isArrayLike from "lodash-es/isArrayLike";
import identity from "lodash-es/identity";
import isError from "lodash-es/isError";
import cloneDeep from "lodash-es/cloneDeep";
import toString from "lodash-es/toString";

import {Duration} from "luxon";
import {IStyleSheetContext} from "styled-components";
import {MaybeNil} from "app/utils/types";
import {ForwardedRef} from "react";
import {DropdownMenuItemType, IDropdownOption} from "@fluentui/react";
import {customAlphabet} from "nanoid";
import {FieldValues, SubmitErrorHandler} from "react-hook-form";
import BigNumber from "bignumber.js";
import {freeze} from "immer";

export {isEqual, isEmpty, capitalize, isArrayLike, identity, isError, toString, cloneDeep};

// the missing boolean XOR logical operation in JS
export const booleanXor = (a: boolean, b: boolean): boolean => (a || b) && !(a && b);

export const numberCompareFn = (a: number, b: number) => a - b;
export const durationCompareFn = (a: Duration, b: Duration) => (a < b ? -1 : a > b ? 1 : 0);

// sleep time expects milliseconds
export const delay = (time: number, signal?: AbortSignal) =>
  new Promise((resolve, reject) => {
    const timer = setTimeout(resolve, time);
    signal?.addEventListener("abort", () => {
      clearTimeout(timer);
      reject();
    });
  });

export const isNil = <T>(value: T | null | undefined): value is null | undefined => {
  return value === null || value === undefined;
};

export const isNotNil = <T>(value: T | null | undefined): value is T => {
  return !isNil(value);
};

export const assertNotNil: <T>(value: T | null | undefined) => asserts value is T = <T>(
  value: T | null | undefined
): asserts value is T => {
  if (isNil(value)) {
    throw Error("value is nil");
  }
};

export const assertCondition: (condition: boolean, message?: string) => asserts condition = (condition, message) => {
  if (!condition) {
    throw Error(message !== undefined ? `assertCondition: ${message}` : "condition is false");
  }
};

export const chunk = (str: string, numChunks: number) => {
  const chunks = [];
  const charsLength = str.length;

  for (let i = 0; i < charsLength; i += numChunks) {
    chunks.push(str.substring(i, Math.min(charsLength, i + numChunks)));
  }

  return chunks;
};

export const percent = (numerator: number, denominator: number): string => {
  const percentFloat = (denominator === 0 ? 0 : numerator / denominator) * 100;
  return `${Math.round(percentFloat)}%`;
};

export const fractionToPercent = (fraction: number): string => {
  return `${Math.round(fraction * 100)}%`;
};

export const scoreToPercent = (score: string): string => {
  return `${Math.round(Number(score))}%`;
};

export const generatePassword = (length = 12) => {
  const charset = "abcdefghjkmnpqrstuvwxyz23456789";
  let retVal = "";
  for (let i = 0; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * charset.length));
  }
  return retVal;
};

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export const humanFileSize = (bytes: number, si = true, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + " " + units[u];
};

export const mapReversed = <T, U>(array: T[], fn: (value: T, index: number, array: T[]) => U): U[] => {
  const result = [];
  for (let i = array.length - 1; i >= 0; i--) {
    result.push(fn(array[i], i, array));
  }
  return result;
};

export const DEBOUNCE_DELAY = 250;

/**
 * By default forwards all props except transient ones.
 */
export const forwardAllProps: IStyleSheetContext["shouldForwardProp"] = () => true;

export const isNotBlank = (str: MaybeNil<string>): str is string => {
  return !isNil(str) && str.trim().length > 0;
};

const whiteSpaceRegex = /\s+/g;
export const normalizeWhitespace = (str: MaybeNil<string>) => str?.replace(whiteSpaceRegex, " ")?.trim() ?? "";

export const normalizeToNull = (v: MaybeNil<string>): string | null => {
  const trimmed = normalizeWhitespace(v);
  return trimmed === "" ? null : trimmed;
};

export const emptyToNull = (v: MaybeNil<string>): string | null => {
  return isNil(v) || v === "" ? null : v;
};

export const emptyListToNull = <T>(list: MaybeNil<T[]>): MaybeNil<T[]> => (list?.length === 0 ? null : list);

export const assignRef = <T>(ref: ForwardedRef<T>, element: T | null) => {
  if (typeof ref === "function") {
    ref(element);
  } else if (ref) {
    ref.current = element;
  }
};

// eslint-disable-next-line no-undef
export const defaultScrollIntoView: ScrollIntoViewOptions = {
  behavior: "smooth",
  block: "center",
  inline: "center"
} as const;

export const downloadUrlAsFile = async (url: string, fileName: string, signal?: AbortSignal) => {
  // Fetch the content from the URL
  const response = await fetch(url, {signal, redirect: "follow"});

  // Check if the fetch was successful
  if (!response.ok) {
    throw new Error(`Failed to fetch the URL ${url}: ${response.status} ${response.statusText}`);
  }

  // Convert the response to a blob
  const blob = await response.blob();

  // Convert the blob to a File object
  return new File([blob], fileName, {type: blob.type});
};

export const prependNoOption = <T>(options: IDropdownOption<T | null>[], text: string) => {
  options.splice(
    0,
    0,
    {key: "__null", text, data: null},
    {key: "__null_divider", itemType: DropdownMenuItemType.Divider, text: ""}
  );
};

/**
 * Workaround for the issue with the Vite inlined SVG icons in the IonIcon component
 * https://github.com/vitejs/vite/blob/0c0aeaeb3f12d2cdc3c47557da209416c8d48fb7/packages/vite/src/node/plugins/asset.ts#L441-L454
 * https://github.com/ionic-team/ionicons/blob/de8b11e8927cb2d4630ecf73015ee46a75910e41/src/components/icon/validate.ts#L52
 */
const fixRegex = /^data:image\/svg\+xml,(.+)$/;
export const fixSvgForIonIcon = (icon: string) =>
  icon.replace(fixRegex, (_, rest) => `data:image/svg+xml;utf8,${decodeURIComponent(rest)}`);

const alphabet = "23456789ABCDEFGHJKLMNPQRSTUVWXYZ";
export const keyNanoId = customAlphabet(alphabet, 8);
export const newChoiceKey = () => `choice_${keyNanoId()}`;
export const newColumnKey = () => `col_${keyNanoId()}`;

export const loggingErrorHandler: SubmitErrorHandler<FieldValues> = (errors) => {
  console.warn("Form submit failed with errors", errors);
};

export const DEFAULT_ROUNDING_MODE = BigNumber.ROUND_HALF_EVEN;

export const EMPTY_ARRAY = freeze([]);

export const prependExisting = <ID, T extends {id: ID}>(list: T[], existing: MaybeNil<T>): T[] => {
  if (isNil(existing)) {
    return list;
  }
  const exists = list.some((item) => isEqual(item.id, existing.id));
  if (exists) {
    return list;
  } else {
    return [existing, ...list];
  }
};
